/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, CSSProperties } from 'react';
import './Modal.css';
import { useAuthenticationConfig } from '../contexts/AuthenticationConfigContext';

type ModalProps = {
    description?: string;
    showButtons: boolean;
    firstOptionText: string;
    firstOptionHandler: React.MouseEventHandler<HTMLDivElement>;
    isVisible: boolean;
    secondOptionText: string;
    secondOptionHandler: React.MouseEventHandler<HTMLDivElement>;
    title: string;
};

const Modal: React.FC<ModalProps> = (props) => {
    const config = useAuthenticationConfig();
    const wrapperStyle =
        config.modalsZIndex === undefined || config.modalsZIndex === null
            ? {}
            : ({ zIndex: config.modalsZIndex } as CSSProperties);

    return (
        <div data-auth-okta className={`wrapper ${props.isVisible ? '' : 'disabled'}`} style={wrapperStyle}>
            <div className="overlay" />
            <div className="alert">
                <div className="header">
                    <div className="content">
                        <div className="title">{props.title}</div>
                        <div className="description">{props.description}</div>
                    </div>
                </div>
                {props.showButtons ? (
                    <div className="actions space-x-4">
                        <div className="grow" />
                        <div className="button" onClick={props.firstOptionHandler}>
                            {props.firstOptionText}
                        </div>
                        <div className="button" onClick={props.secondOptionHandler}>
                            {props.secondOptionText}
                        </div>
                    </div>
                ) : null}
            </div>
        </div>
    );
};

export default Modal;
