import React, { useState } from 'react';
import { useAuthenticationConfig } from '../contexts';
import Modal from './Modal';

type LogoutWarningModalProps = {
    description: string;
    isVisible: boolean;
    showActions: boolean;
    handleLogout: React.MouseEventHandler<HTMLDivElement>;
    handleStayConnected: React.MouseEventHandler<HTMLDivElement>;
};

const LogoutWarningModal: React.FC<LogoutWarningModalProps> = (props, { children }) => {
    const config = useAuthenticationConfig();
    if (!config.showLogoutWarning) {
        return <>{children}</>;
    }

    return (
        <>
            {children}
            <Modal
                title="Are you still there?"
                showButtons={props.showActions}
                description={props.description}
                firstOptionText="Logout"
                firstOptionHandler={props.handleLogout}
                isVisible={props.isVisible}
                secondOptionText="Stay Connected"
                secondOptionHandler={props.handleStayConnected}
            />
        </>
    );
};

export default LogoutWarningModal;
