import React from 'react';
import { AuthState, CustomUserClaims, UserClaims } from '@okta/okta-auth-js';
import { useOktaAuth } from '@okta/okta-react';
import { useToken, useAuthenticationConfig } from '@samc/vmsnext-authentication-okta';
import { ClientConfiguration } from '@samc/single-spa-client-configuration';
import useLocalStorage from '../hooks/useLocalStorage';
import { TOKEN_KEY } from '../hooks/useToken';
import { AUTHENTICATION_CONFIG_KEY } from '../hooks/useAuthenticationConfig';
import { emitUserToken, emitUserConfig, emitUserAuthState, emitUser } from '../utilities/userConfigData';

export interface AuthenticationManagerProps {
    onAuthenticated?: (user: UserClaims<CustomUserClaims>) => void;
}

export const AuthenticationManager = (props: AuthenticationManagerProps): React.ReactElement => {
    const { onAuthenticated } = props;

    const token = useToken();
    const config = useAuthenticationConfig() as ClientConfiguration;
    const { authState, oktaAuth } = useOktaAuth();

    const [, setToken] = useLocalStorage(TOKEN_KEY);
    const [, setAuthenticationConfig] = useLocalStorage(AUTHENTICATION_CONFIG_KEY);
    const [, setUserAuthenticated] = React.useState<AuthState | null>(null);
    const [, setUser] = React.useState<UserClaims | null>(null);

    React.useEffect(() => {
        oktaAuth.getUser().then((u) => {
            setUser(u);
            emitUser(u);
            onAuthenticated?.(u);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [oktaAuth]);

    React.useEffect(() => {
        setUserAuthenticated(authState);
        emitUserAuthState(authState);
    }, [authState]);

    React.useEffect(() => {
        setToken(token);
        emitUserToken(token);
    }, [token, setToken]);

    React.useEffect(() => {
        setAuthenticationConfig(config);
        emitUserConfig(config);
    }, [config, setAuthenticationConfig]);

    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <></>;
};

export default AuthenticationManager;
