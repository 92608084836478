import React, { PropsWithChildren } from 'react';
import '@samc/vmsnext-authentication-okta/dist/index.css';
import '@samc/vmsnext-authorization/dist/index.css';
import './global.css';
import { Authentication, AuthenticationConfig, useToken } from '@samc/vmsnext-authentication-okta';
import { ClientConfiguration, useClientConfiguration, useTenantId } from '@samc/single-spa-client-configuration';
import { RequireTermsOfUse } from '@samc/vmsnext-authorization';
import { AuthenticationManager } from './components/AuthenticationManager';
import { TokenButton } from './atoms/TokenButton/TokenButton';
import { AuthorizationWrapper } from './components/AuthorizationWrapper';

const InnerRoot = (props: PropsWithChildren): React.ReactElement | null => {
    const { children } = props;
    const { result: clientConfiguration } = useClientConfiguration();

    const token = useToken();
    const tenantId = useTenantId();

    const wrapperConfig = React.useMemo((): ClientConfiguration | undefined => {
        if (!clientConfiguration) return undefined;
        const { defaultHeaders: _inputDefaultHeaders = [] } = clientConfiguration;

        const defaultHeaders = [..._inputDefaultHeaders];
        defaultHeaders.push({
            name: 'X-Tenant',
            value: tenantId,
        });

        return {
            ...clientConfiguration,
            defaultHeaders,
        };
    }, [clientConfiguration, tenantId]);

    if (!wrapperConfig) return null;
    return (
        <AuthorizationWrapper config={wrapperConfig} jwt={token}>
            {/** We do not want to show TOU until a user enters a tenant */}
            {tenantId && <RequireTermsOfUse />}
            {children}
        </AuthorizationWrapper>
    );
};

export const Root = (): React.ReactElement | null => {
    const { result: clientConfiguration } = useClientConfiguration();

    const config = React.useMemo<ClientConfiguration | undefined>(() => {
        if (!clientConfiguration) return undefined;
        return {
            ...clientConfiguration,
            // Disabled to prevent conflict with current user setting in @samc/vmsnext-authorization
            useOktaUser: false,
            modalsZIndex: '300000',
        };
    }, [clientConfiguration]);

    if (!config) return null;

    return (
        <Authentication config={config as AuthenticationConfig}>
            <InnerRoot>
                <AuthenticationManager />
                <TokenButton />
            </InnerRoot>
        </Authentication>
    );
};

export default Root;
