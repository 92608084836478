import React from 'react';
import { useAuthenticationConfig } from './contexts';
import { saveLastAccessedPath } from './helpers/loginRedirectPathHelpers';
import { WithChildren } from './types';

const IGNORE_PATHS = ['/login', '/logout', '/loggedOut'];

interface LastAccessedPathTrackingProps extends Partial<WithChildren> {
    /**
     * Keeps tracking from happening
     */
    suspendTracking?: boolean;
}

const LastAccessedPathTracking: React.FC<LastAccessedPathTrackingProps> = ({ children, suspendTracking }) => {
    const { ignoredLastRedirectUrlMatchers, loginRedirectStrategy } = useAuthenticationConfig();

    React.useEffect(() => {
        if (suspendTracking) return undefined;

        if (loginRedirectStrategy === 'ToLastVisitedUrl') {
            const handler = (): void => {
                if (IGNORE_PATHS.includes(window.location.pathname)) return;
                if (
                    ignoredLastRedirectUrlMatchers &&
                    ignoredLastRedirectUrlMatchers.some((m) => m.test(window.location.href))
                )
                    return;

                saveLastAccessedPath(window.location.href);
            };

            handler(); // initial run
            const tick = setInterval(handler, 1000);
            return () => clearInterval(tick);
        }

        return undefined;
    }, [suspendTracking]);

    return <>{children}</>;
};

export default LastAccessedPathTracking;
