import { Guid } from '@samc/common';
import { ISelectOption, ISelectedOption } from '../interfaces/ISelectOption';

abstract class Base<T> {
    public id: Guid;

    public name: string;

    public isSystemControlled: boolean;

    // isDirty is intended to be a very basic check of "can I call the create/update endpoint for the object?"
    // Not included, for example, are changing roles for users, or entitlements for roles, or flagging Disabled for a user.
    // That's because all of those are separate endpoints and should be checked separately. Therefore, additional dirty
    // methods may be on the implementing classes, or handled directly in the form (UserForm handles checking the dirty
    // status for isDisabled, for example).
    abstract isDirty(original: T): boolean;

    abstract getDirtyFields(original: T): string[];

    abstract clone(): T;

    constructor(id?: Guid, name?: string) {
        this.id = id || Guid.createEmpty();
        this.name = name ?? '';
        this.isSystemControlled = false;
    }

    static toSelectOption<T extends Base<T>>(model: T): ISelectOption<T> {
        return { label: model.name, value: model };
    }

    static toSelectOptions<T extends Base<T>>(companies: T[], selectedCompany?: T | null): ISelectedOption<T> {
        const selectedOption = selectedCompany ? this.toSelectOption(selectedCompany) : null;
        let selectOptions = companies
            .filter((c) => selectedOption === null || !c.id.equals(selectedOption?.value.id))
            .map((c) =>
                selectedOption != null && c.id.equals(selectedOption.value.id)
                    ? selectedOption
                    : this.toSelectOption(c),
            );

        if (selectedOption !== null) {
            selectOptions = [selectedOption, ...selectOptions];
        }

        return { all: selectOptions, selected: selectedOption };
    }
}

export default Base;
