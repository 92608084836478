import { OktaAuth } from '@okta/okta-auth-js';
import { AuthenticationConfig } from '../models/AuthenticationConfig';

export default function toOktaAuth(config: AuthenticationConfig): OktaAuth {
    const baseUrl = config.baseUrl ?? `${window.location.protocol}//${window.location.host}`;

    return new OktaAuth({
        issuer: config.issuer,
        redirectUri: `${baseUrl}`,
        postLogoutRedirectUri: `${baseUrl}/loggedOut`,
        clientId: config.clientId,
        pkce: true,
        tokenManager: {
            autoRenew: config.tokenRefreshMethod === 'Auto',
            // If we're doing auto-renew, we do it early to ensure plenty of time.
            // Otherwise, we set it to not expire early, for differing reasons.
            // 1. If TokenRefreshMethod is none, we don't want to kick the user out until their token actually expires.
            // 2. For MouseMovement / Interaction, we have to refresh the tokens manually, so early expiration provides no value.
            expireEarlySeconds: config.tokenRefreshMethod === 'Auto' ? 120 : 0,
        },
    });
}
