import { IEvent, Guid } from '@samc/common';

export class EntitlementsChangedOnRoleEvent implements IEvent {
    public name: string = EntitlementsChangedOnRoleEvent.eventName;

    static eventName = 'EntitlementsChangedOnRoleEvent' as const;

    roleId: Guid;

    constructor(roleId: Guid) {
        this.roleId = roleId;
    }
}
