import { ColDef } from 'ag-grid-community';
import { Renderers } from '../components/grid/GridRenderers';

type ColumnOptions = {
    resizable?: boolean;
    sortable?: boolean;
    filter?: string;
    enableFilterButton?: boolean;
};

export const dateFilterParams = {
    buttons: ['apply', 'reset'],
    inRangeInclusive: true,
    closeOnApply: true,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    comparator: (filterLocalDateAtMidnight: any, cellValue: any): 0 | -1 | 1 => {
        if (cellValue === null) return -1;
        let cellDate = new Date(cellValue);
        cellDate = new Date(cellDate.getFullYear(), cellDate.getMonth(), cellDate.getDate());

        if (cellDate < filterLocalDateAtMidnight) {
            return -1;
        }
        if (cellDate > filterLocalDateAtMidnight) {
            return 1;
        }
        return 0;
    },
};

/**
 *
 * @param header The name to display to users
 * @param field The field on the object this column represents
 * @param cellRenderer The renderer to use. Default is going to be the default standard text output of ag-Grid
 * @param defaultedOptions Options which, if you don't provide a specific value, will default to true (or "agTextColumnFilter" for filter). Pass null to filter if you don't want a filter.
 * @param additionalOptions colDef parameters which are not provided by default and will be appended to the colDef
 */
export function columnDef(
    header: string,
    field: string,
    cellRenderer: Renderers = Renderers.Default,
    defaultedOptions: ColumnOptions | undefined = undefined,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    additionalOptions: any = undefined,
): ColDef {
    const renderer = cellRenderer === Renderers.Default ? undefined : cellRenderer.toString();
    // since this confused me when I looked at it for the first time in months, 'named' means is a specific parameter in this method
    const namedFields: ColDef = {
        headerName: header,
        field,
        cellRenderer: renderer,
        resizable: defaultedOptions?.resizable !== undefined ? defaultedOptions?.resizable : true,
        sortable: defaultedOptions?.sortable !== undefined ? defaultedOptions.sortable : true,
        filter: defaultedOptions?.filter !== undefined ? defaultedOptions.filter : 'agTextColumnFilter',
        floatingFilter: defaultedOptions?.filter !== null,
        floatingFilterComponentParams: { suppressFilterButton: !defaultedOptions?.enableFilterButton },
        filterParams: {
            buttons: ['apply', 'reset'],
            inRangeInclusive: true,
            closeOnApply: true,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            comparator: (filterLocalDateAtMidnight: any, cellValue: any) => {
                if (cellValue === null) return -1;
                let cellDate = new Date(cellValue);
                cellDate = new Date(cellDate.getFullYear(), cellDate.getMonth(), cellDate.getDate());

                if (cellDate < filterLocalDateAtMidnight) {
                    return -1;
                }
                if (cellDate > filterLocalDateAtMidnight) {
                    return 1;
                }
                return 0;
            },
        },
    };

    return additionalOptions ? { ...namedFields, ...additionalOptions } : namedFields;
}
