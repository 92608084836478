import React, { useState, useRef, CSSProperties } from 'react';
import { AgGridReact } from 'ag-grid-react';
import {
    ColDef,
    ColGroupDef,
    GridReadyEvent,
    FirstDataRenderedEvent,
    ModuleRegistry,
    CsvExportModule,
    CellValueChangedEvent,
    MenuItemDef,
    GetContextMenuItems,
} from 'ag-grid-community';
import './Grid.css';
import { GridButtonProps } from './GridButton';
import { addRenderers } from './GridRenderers';
import ButtonBar from './ButtonBar';

ModuleRegistry.registerModules([CsvExportModule]);

export type GridProps<TData> = {
    columnDefs: (ColDef | ColGroupDef)[];
    rowData?: TData[];
    wrapperClassName?: string;
    buttonProps?: Array<GridButtonProps>;
    paging?: boolean;
    pageSize?: number;
    excelExportAction?: () => void;
    canUseFullScreen?: boolean;
    containerStyling?: CSSProperties;
    getContextMenuItems?: (data?: TData) => (MenuItemDef | string)[];
    onGridReady?: (event: GridReadyEvent) => void;
    onFirstDataRendered?: (event: FirstDataRenderedEvent) => void;
    onCellValueChanged?: (event: CellValueChangedEvent) => void;
    excelTooltip?: string;
    fullScreenTooltip?: string;
    clearFiltersTooltip?: string;
};

const Grid = <TData,>({
    columnDefs,
    rowData,
    wrapperClassName = '',
    buttonProps = new Array<GridButtonProps>(),
    onGridReady,
    onFirstDataRendered,
    onCellValueChanged,
    paging = false,
    excelExportAction,
    canUseFullScreen = false,
    containerStyling,
    getContextMenuItems,
    clearFiltersTooltip = 'Clear Filters',
    excelTooltip = 'Export to Excel',
    fullScreenTooltip = 'Toggle Full Screen',
}: GridProps<TData>): React.ReactElement => {
    const agGrid = useRef<AgGridReact>(null);
    const [isFullScreen, setIsFullScreen] = useState(false);

    const clearFilterAction = (): void => {
        agGrid.current?.api?.setFilterModel(null);
        agGrid.current?.api?.onFilterChanged();
    };

    const fullScreenFunc = canUseFullScreen
        ? (): void => {
              setIsFullScreen(!isFullScreen);
          }
        : undefined;

    const className = `ag-theme-balham bg-mono-15 h-full flex flex-col ${wrapperClassName} ${isFullScreen ? 'fullscreen' : ''}`;
    const frameworkComponents: Record<string, unknown> = {};
    addRenderers(frameworkComponents);

    const contextMenuAction: GetContextMenuItems<TData> | undefined = getContextMenuItems
        ? (params): (string | MenuItemDef<TData>)[] => getContextMenuItems(params.node?.data)
        : undefined;

    return (
        <div className={className} style={containerStyling}>
            <div className="flex-grow-0">
                <ButtonBar
                    buttonProps={buttonProps}
                    clearFiltersTooltip={clearFiltersTooltip}
                    clearFilterFunc={clearFilterAction}
                    isFullScreen={isFullScreen}
                    fullScreenFunc={fullScreenFunc}
                    excelFunc={excelExportAction}
                    excelTooltip={excelTooltip}
                    fullScreenTooltip={fullScreenTooltip}
                />
            </div>
            <div className="flex-grow">
                <AgGridReact
                    disableStaticMarkup // https://www.ag-grid.com/react-fine-tuning/#react-cell-rendering - disabled this because it causes the very first cell to frequently render twice
                    ref={agGrid}
                    defaultColDef={{
                        filter: true,
                        resizable: true,
                        sortable: true,
                    }}
                    columnDefs={columnDefs}
                    rowData={rowData}
                    components={frameworkComponents}
                    onGridReady={onGridReady}
                    onFirstDataRendered={onFirstDataRendered}
                    onCellValueChanged={onCellValueChanged}
                    pagination={paging}
                    paginationAutoPageSize
                    accentedSort
                    getContextMenuItems={contextMenuAction}
                />
            </div>
        </div>
    );
};
export default Grid;
