import React, { useEffect, useState } from 'react';
import { DefaultPublisher } from '@samc/common';
import { User } from '../models';
import { useClient } from './ClientContext';
import { useCurrentUser } from './CurrentUserContext';
import Entitlements from '../Entitlements';
import { UserSavedEvent } from '../events/UserSavedEvent';
import { WithChildren } from '../types';

type Users = {
    map: Map<string, User>;
    mapByUserName: Map<string, User>;
    array: Array<User>;
};

const UserContext = React.createContext<Users | null | undefined>(undefined);

const UsersProvider: React.FC<Partial<WithChildren>> = ({ children }) => {
    const client = useClient();
    const currentUser = useCurrentUser();
    const [users, setUsers] = useState<Users | null>(null);
    const reloadUsers = (): void => {
        setUsers(null);
        if (currentUser.hasEntitlement(Entitlements.Users.List)) {
            client.users.listUsers().then((u) => {
                const map = new Map<string, User>();
                const mapByUserName = new Map<string, User>();

                u.forEach((user) => {
                    map.set(user.id.toString(), user);
                    mapByUserName.set(user.username.toLowerCase(), user);
                });

                setUsers({ map, array: u, mapByUserName });
            });
        }
    };
    useEffect(() => {
        if (client.isReady && currentUser.isLoaded && !currentUser.isDisabled) {
            reloadUsers();
        }
    }, [client, currentUser]);

    useEffect(() => {
        const subscriptionId = DefaultPublisher.subscribe<UserSavedEvent>(UserSavedEvent.eventName, reloadUsers);
        return () => DefaultPublisher.unsubscribe(UserSavedEvent.eventName, subscriptionId);
    }, [client, currentUser]);

    return <UserContext.Provider value={users}>{children}</UserContext.Provider>;
};

function useUserMap(): Map<string, User> | undefined {
    const context = React.useContext(UserContext);
    if (context === undefined) {
        throw new Error('useUserMap must be used within a UsersProvider!');
    }
    return context?.map;
}

function useUserMapByUserName(): Map<string, User> | undefined {
    const context = React.useContext(UserContext);
    if (context === undefined) {
        throw new Error('useUserMap must be used within a UsersProvider!');
    }
    return context?.mapByUserName;
}

function useUserArray(): User[] | undefined {
    const context = React.useContext(UserContext);
    if (context === undefined) {
        throw new Error('useUserArray must be used within a UsersProvider!');
    }
    return context?.array;
}

export { UsersProvider, useUserMap, useUserArray, useUserMapByUserName };
