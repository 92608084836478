import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';
import { useAuthenticationConfig } from '../contexts/AuthenticationConfigContext';
import PrettyCenteredBox from '../components/PrettyCenteredBox';

const Logout: React.FC = () => {
    const { oktaAuth, authState } = useOktaAuth();
    const { appName, returnToApplicationPath, cookieUrl } = useAuthenticationConfig();

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const log = (window as any).$log || console; // use centricity client logging if available.

    // logout of the application session by clearing the tokens.
    // Note: We should not logout of the SSO session using signOut()
    const appSessionLogout = async (): Promise<void> => {
        log.info('Logout#appSessionLogout: logging out', 'Component: Auth');

        try {
            if (cookieUrl) {
                const response = await fetch(cookieUrl, { method: 'DELETE' });

                if (response.ok) log.error('Logout#appSessionLogout: Cookie deleted', 'Component: Auth');
                else log.error('Logout#appSessionLogout: failed deleting cookie', 'Component: Auth');
            }

            oktaAuth.tokenManager.clear();
            log.info('Logout#appSessionLogout: cleared tokens', 'Component: Auth');
        } catch (err) {
            log.error('Logout#appSessionLogout:', 'Component: Auth', err);
            throw err;
        }
    };

    useEffect(() => {
        if (authState !== null && authState.isAuthenticated) {
            log.info(
                `logout page: authState is not authenticated or we have no accessToken - logging out`,
                'Component: Auth',
                authState,
            );
            const timer = setTimeout(appSessionLogout, 3000);

            return () => clearTimeout(timer);
        }

        return undefined;
    }, [authState]);

    React.useEffect(() => {
        log.info(`loading logout page`, 'Component: Auth', authState);
    }, [authState]);

    if (authState !== null && !authState.isAuthenticated) {
        // User is already logged out
        return <Redirect to="/loggedOut" />;
    }

    return (
        <PrettyCenteredBox>
            <p className="mt-xl font-bold text-2">You are being logged out of {appName}.</p>
            <p className="text-2">
                If you want to remain logged in,{' '}
                <a href={returnToApplicationPath ?? '/'} className="text-blue-5 underline">
                    click here
                </a>
                .
            </p>
        </PrettyCenteredBox>
    );
};

export default Logout;
