import { IEvent } from '@samc/common';
import { Role } from '../models';

export class RoleSavedEvent implements IEvent {
    public name: string = RoleSavedEvent.eventName;

    static eventName = 'RoleSavedEvent' as const;

    role: Role;

    constructor(role: Role) {
        this.role = role;
    }
}
