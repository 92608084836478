import useLocalStorage from './useLocalStorage';

export const TOKEN_KEY = '@samc/single-spa-authentication__token';

export const useToken = (): string | null => {
    const [token] = useLocalStorage(TOKEN_KEY);

    if (typeof token === 'string') {
        return token;
    }
    return null;
};

export default useToken;
