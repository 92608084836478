import { Authorization as InnerAuthorization, AuthorizationConfig } from '@samc/vmsnext-authorization';
import { ClientConfiguration } from '@samc/single-spa-client-configuration';
import React from 'react';

export interface AuthorizationWrapperProps {
    config: ClientConfiguration;
    jwt?: string | null;
    children?: React.ReactNode;
}

const fieldsRequiredInAuthorizationConfig = [
    'agGridLicense',
    'apiUrl',
    'appName',
    'authenticationScheme',
    'contactEmail',
    'helpLink',
    'platformName',
    'requireTermsOfUseAcceptance',
];

const isValidAuthorizationConfig = (config: Partial<ClientConfiguration>): config is AuthorizationConfig =>
    fieldsRequiredInAuthorizationConfig.every((f) => Reflect.get(config, f) !== undefined);

export const AuthorizationWrapper = ({ config, jwt, children }: AuthorizationWrapperProps): React.ReactElement => {
    const [gracePeriodActive, setGracePeriodActive] = React.useState(true);
    React.useEffect(() => {
        const timer = setTimeout(() => setGracePeriodActive(false), 5000);
        return () => clearTimeout(timer);
    }, []);

    if (!isValidAuthorizationConfig(config)) {
        if (!gracePeriodActive)
            // eslint-disable-next-line no-console
            console.error('Cannot render Authorization due to missing required configuration values', {
                missingFields: fieldsRequiredInAuthorizationConfig.filter((f) => Reflect.get(config, f) === undefined),
                providedConfig: config,
            });
        // Do not render children since anything expecting a context provider will break
        return <div id="bad_authorization_config" />;
    }
    return (
        <InnerAuthorization
            config={{
                suppressUserLoadingMessage: true,
                ...config,
            }}
            jwt={jwt}
        >
            {children}
        </InnerAuthorization>
    );
};
