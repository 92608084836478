import React, { useEffect, useState } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { useAuthenticationConfig } from './contexts';
import LogoutWarningModal from './components/LogoutWarningModal';
import { WithChildren } from './types';

const InnerMouseMovementTokenRefresh: React.FC<Partial<WithChildren>> = ({ children }) => {
    const config = useAuthenticationConfig();

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const log = (window as any).$log || console; // use centricity client logging if available.
    const { oktaAuth, authState } = useOktaAuth();
    const [lastMovement, setLastMovement] = useState(new Date());
    const [isLogoutWarningVisible, setIsLogoutWarningVisible] = useState(false);

    const handleLogout: React.MouseEventHandler<HTMLDivElement> = () => {
        oktaAuth.signOut();
    };

    const handleStayConnected: React.MouseEventHandler<HTMLDivElement> = () => {
        oktaAuth.tokenManager.renew('idToken');
        oktaAuth.tokenManager.renew('accessToken');
        setIsLogoutWarningVisible(false);
    };

    log.info('using MouseMovementTokenRefresh strategy', 'Component: Auth');

    // Sets a flag to true when the mouse gets moved. An interval fires every few seconds to update the lastMovement value
    // Interval is used here to reduce the frequency of the mouse movement hits altering the React state.
    useEffect(() => {
        let hasMouseMoved = false;
        const interval = setInterval(() => {
            // every 3 seconds we check if there was mouse movement, set the last movement Date, and reset hasMouseMoved
            if (hasMouseMoved) {
                setLastMovement(new Date());
                hasMouseMoved = false;
            }
        }, 3000);
        const mouseMoved = (): void => {
            hasMouseMoved = true;
        };
        document.addEventListener('mousemove', mouseMoved);
        return () => {
            document.removeEventListener('mousemove', mouseMoved);
            clearInterval(interval);
        };
    }, [config]);

    useEffect(() => {
        const interval = setInterval(() => {
            if (authState === null || !authState.isAuthenticated || !authState.accessToken) return;
            const now = new Date();
            const secondsRemaining = authState.accessToken.expiresAt - now.getTime() / 1000;

            if (
                secondsRemaining < 300 &&
                now < new Date(lastMovement.getTime() + 300000) /* If last mouse movement happened in last 5 minutes */
            ) {
                log.info('Active user; renewing tokens', 'Component: Auth');
                oktaAuth.tokenManager.renew('idToken');
                oktaAuth.tokenManager.renew('accessToken');
                setIsLogoutWarningVisible(false);
            } else if (secondsRemaining < 120) {
                log.info('Due to inactivity; showing logout warning', 'Component: Auth');
                setIsLogoutWarningVisible(true);
            }
        }, 30000);
        return () => {
            clearInterval(interval);
        };
    }, [lastMovement, authState, oktaAuth]);

    return (
        <>
            {children}
            {config.showLogoutWarning ? (
                <LogoutWarningModal
                    description="Your session is about to expire. Move your mouse to extend your session."
                    isVisible={isLogoutWarningVisible}
                    showActions={false} // mouse movement extends session so no need to show actions
                    handleLogout={handleLogout}
                    handleStayConnected={handleStayConnected}
                />
            ) : null}
        </>
    );
};

const MouseMovementTokenRefresh: React.FC<WithChildren> = ({ children }) => {
    const config = useAuthenticationConfig();

    if (config.tokenRefreshMethod !== 'MouseMovement') return <>{children}</>;

    return <InnerMouseMovementTokenRefresh>{children}</InnerMouseMovementTokenRefresh>;
};

export default MouseMovementTokenRefresh;
