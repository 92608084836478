import { AuthenticationConfig } from '@samc/vmsnext-authentication-okta';
import useLocalStorage from './useLocalStorage';

export const AUTHENTICATION_CONFIG_KEY = '@samc/single-spa-authentication__authenticationConfig';

export const useAuthenticationConfig = (): AuthenticationConfig | null => {
    const [config] = useLocalStorage(AUTHENTICATION_CONFIG_KEY);

    return config as AuthenticationConfig;
};

export default useAuthenticationConfig;
