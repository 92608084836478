import React, { useContext } from 'react';
import AuthConfig from '../models/AuthorizationConfig';
import { WithChildren } from '../types';

const AuthorizationConfigContext = React.createContext<AuthConfig | undefined>(undefined);
type AuthProps = WithChildren & {
    config: AuthConfig;
};

function AuthorizationConfigProvider({ config, children }: AuthProps): React.ReactElement {
    return <AuthorizationConfigContext.Provider value={config}>{children}</AuthorizationConfigContext.Provider>;
}

function useAuthorizationConfig(): AuthConfig {
    const context = useContext(AuthorizationConfigContext);
    if (context === undefined) {
        throw new Error('useAuthorizationConfig must be used within an AuthorizationConfigProvider!');
    }
    return context;
}

export { AuthorizationConfigProvider, useAuthorizationConfig };
