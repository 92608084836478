import React from 'react';
import { AuthorizationConfigProvider } from './contexts/AuthorizationConfigContext';
import { ClientProvider } from './contexts/ClientContext';
import { CurrentUserProvider } from './contexts/CurrentUserContext';
import AuthorizationConfig from './models/AuthorizationConfig';
import ValidUserCheck from './ValidUserCheck';
import { WithChildren } from './types';

type AuthorizationProps = Partial<WithChildren> & {
    config: AuthorizationConfig;
    jwt?: string | null;
};

const Authorization: React.FC<AuthorizationProps> = ({ config, jwt, children }) => {
    const components = (
        <AuthorizationConfigProvider config={config}>
            <ClientProvider jwt={jwt}>
                <CurrentUserProvider>
                    <ValidUserCheck>{children}</ValidUserCheck>
                </CurrentUserProvider>
            </ClientProvider>
        </AuthorizationConfigProvider>
    );
    return config.setContainerId ? <div id="auth">{components}</div> : components;
};

export default Authorization;
