import React, { useContext, useEffect } from 'react';
import { MessageBus } from '@samc/message-bus';
import { WithChildren } from '../types';

const MessageBusContext = React.createContext<MessageBus | null>(null);
type MessageBusProviderProps = WithChildren & { bus?: MessageBus };

function MessageBusProvider({ bus, children }: MessageBusProviderProps): React.ReactElement {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const log = (window as any).$log || console; // use centricity client logging if available.

    useEffect(() => {
        if (bus !== undefined) {
            log.info('Starting the message bus with provider');
            bus.start();
        }
        return () => {
            if (bus !== undefined) {
                log.info('Closing the message bus with the provider');
                bus.close();
            }
        };
    }, []);

    return <MessageBusContext.Provider value={bus ?? null}>{children}</MessageBusContext.Provider>;
}

function useMessageBus(): MessageBus | null {
    return useContext(MessageBusContext);
}

export { MessageBusProvider, useMessageBus };
