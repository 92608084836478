import { IEvent } from '@samc/common';
import { Role } from '../models';

export class EditRoleEvent implements IEvent {
    public name: string = EditRoleEvent.eventName;

    static eventName = 'EditRoleEvent' as const;

    role: Role;

    asClone: boolean;

    constructor(role: Role, asClone: boolean = false) {
        this.role = role;
        this.asClone = asClone;
    }
}
