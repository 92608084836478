import React, { useContext } from 'react';
import { AuthenticationConfig } from '../models';
import { WithChildren } from '../types';

const AuthenticationConfigContext = React.createContext<AuthenticationConfig | undefined>(undefined);
type AuthProps = WithChildren & { config: AuthenticationConfig };

function AuthenticationConfigProvider({ config, children }: AuthProps): React.ReactElement {
    return <AuthenticationConfigContext.Provider value={config}>{children}</AuthenticationConfigContext.Provider>;
}

function useAuthenticationConfig(): AuthenticationConfig {
    const context = useContext(AuthenticationConfigContext);
    if (context === undefined) {
        throw new Error('useAuthConfig must be used within an AuthConfigProvider!');
    }
    return context;
}

export { AuthenticationConfigProvider, useAuthenticationConfig };
