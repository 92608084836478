import React, { useEffect, useState } from 'react';
import { DefaultPublisher, setData } from '@samc/common';
import { Entitlement } from '../models';
import { useClient } from './ClientContext';
import { useCurrentUser } from './CurrentUserContext';
import Entitlements from '../Entitlements';
import { UserSavedEvent } from '../events/UserSavedEvent';

import { WithChildren } from '../types';

type Entitlements = {
    idMap: Map<string, Entitlement>;
    array: Array<Entitlement>;
    groupMap: Map<string, Entitlement[]>;
};

const EntitlementsContext = React.createContext<Entitlements | null | undefined>(undefined);

const EntitlementsProvider: React.FC<Partial<WithChildren>> = ({ children }) => {
    const client = useClient();
    const currentUser = useCurrentUser();
    const [entitlements, setEntitlements] = useState<Entitlements | null>(null);
    const reloadEntitlements = (): void => {
        setEntitlements(null);
        if (currentUser.hasEntitlement(Entitlements.Entitlements.List)) {
            client.entitlements.listEntitlements().then((e) => {
                const groupMap = new Map<string, Entitlement[]>();
                const idMap = new Map<string, Entitlement>();

                e.forEach((entitlement) => {
                    if (!groupMap.has(entitlement.group)) {
                        groupMap.set(entitlement.group, new Array<Entitlement>());
                    }
                    groupMap.get(entitlement.group)!.push(entitlement);
                    idMap.set(entitlement.id.toString(), entitlement);
                });

                setData('EntitlementsIdMap', idMap);
                setEntitlements({ groupMap, array: e, idMap });
            });
        }
    };
    useEffect(() => {
        if (client.isReady && currentUser.isLoaded && !currentUser.isDisabled) {
            reloadEntitlements();
        }
    }, [client, currentUser]);

    useEffect(() => {
        const subscriptionId = DefaultPublisher.subscribe<UserSavedEvent>(UserSavedEvent.eventName, reloadEntitlements);
        return () => DefaultPublisher.unsubscribe(UserSavedEvent.eventName, subscriptionId);
    }, [client, currentUser]);

    return <EntitlementsContext.Provider value={entitlements}>{children}</EntitlementsContext.Provider>;
};

function useEntitlementIdMap(): Map<string, Entitlement> | undefined {
    const context = React.useContext(EntitlementsContext);
    if (context === undefined) {
        throw new Error('useEntitlementIdMap must be used within an EntitlementsProvider!');
    }
    return context?.idMap;
}

function useEntitlementGroupMap(): Map<string, Entitlement[]> | undefined {
    const context = React.useContext(EntitlementsContext);
    if (context === undefined) {
        throw new Error('useEntitlementGroupMap must be used within an EntitlementsProvider!');
    }
    return context?.groupMap;
}

function useEntitlementArray(): Entitlement[] | undefined {
    const context = React.useContext(EntitlementsContext);
    if (context === undefined) {
        throw new Error('useEntitlementArray must be used within an EntitlementsProvider!');
    }
    return context?.array;
}

export { EntitlementsProvider, useEntitlementArray, useEntitlementGroupMap, useEntitlementIdMap };
