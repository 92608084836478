import React from 'react';
import { UserClaims } from '@okta/okta-auth-js';
import { user$ } from '../utilities/userConfigData';

export const useUser = (): UserClaims | null => {
    const [user, setUser] = React.useState<UserClaims | null>(null);

    React.useEffect(() => {
        const subscriptor = user$.subscribe((u) => {
            setUser(u);
        });
        return (): void => subscriptor.unsubscribe();
    }, []);

    return user;
};

export default useUser;
