import { IEvent } from '@samc/common';
import { AdditionalUserSettings, User } from '../models';

export class EditUserEvent implements IEvent {
    public name: string = EditUserEvent.eventName;

    static eventName = 'EditUserEvent' as const;

    user: User;

    asClone: boolean;

    additionalUserSettings: AdditionalUserSettings;

    constructor(
        user: User,
        asClone: boolean = false,
        additionalUserSettings: AdditionalUserSettings = new AdditionalUserSettings(),
    ) {
        this.user = user;
        this.asClone = asClone;
        this.additionalUserSettings = additionalUserSettings;
    }
}
