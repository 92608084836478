import React, { useEffect, useState } from 'react';
import debounce from 'lodash.debounce';
import { useOktaAuth } from '@okta/okta-react';
import { AccessToken } from '@okta/okta-auth-js';
import { useAuthenticationConfig } from './contexts';
import LogoutWarningModal from './components/LogoutWarningModal';
import { WithChildren } from './types';
import { AuthenticationWatcher } from './AuthenticationWatcher';

const InnerInteractionTokenRefresh: React.FC<Partial<WithChildren>> = ({ children }) => {
    const config = useAuthenticationConfig();
    const [authenticationWatcher] = React.useState(new AuthenticationWatcher());

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const log = (window as any).$log || console; // use client logging if available.

    const { oktaAuth } = useOktaAuth();

    const [isLogoutWarningVisible, setIsLogoutWarningVisible] = useState(false);

    async function renewTokens(): Promise<void> {
        const idToken = await oktaAuth.tokenManager.renew('idToken');
        log.info(
            `InteractionTokenRefresh#renewTokens: token [idToken] renewed. Expire=[${idToken?.expiresAt}]`,
            'Component: Auth',
        );

        const accessToken = await oktaAuth.tokenManager.renew('accessToken');
        log.info(
            `InteractionTokenRefresh#renewTokens: token [accessToken] renewed. Expire=[${accessToken?.expiresAt}]`,
            'Component: Auth',
        );

        authenticationWatcher.setToken(accessToken as AccessToken);
    }

    async function removeTokens(): Promise<void> {
        try {
            const { cookieUrl } = config;

            if (cookieUrl) {
                const response = await fetch(cookieUrl, { method: 'DELETE' });

                if (response.ok) log.error('InteractionTokenRefresh#removeTokens: Cookie deleted', 'Component: Auth');
                else log.error('InteractionTokenRefresh#removeTokens: failed deleting cookie', 'Component: Auth');
            }

            oktaAuth.tokenManager.clear();

            log.info('InteractionTokenRefresh#removeTokens: cleared tokens', 'Component: Auth');
        } catch (err) {
            log.error('InteractionTokeRefresh#removeTokens:', 'Component: Auth', err);
            throw err;
        }
    }

    async function sessionKeepAlive(): Promise<void> {
        log.debug(`InteractionTokenRefresh#sessionKeepAlive: ${new Date().getTime().toString()}`, 'Component: Auth');

        await oktaAuth.session.refresh();
    }

    function applicationLogout(): void {
        log.info('InteractionTokenRefresh#applicationLogout: logging out', 'Component: Auth');

        window.location.href = '/logout';
    }

    function markInteracted(): void {
        const interactionTime = new Date().getTime().toString();

        log.info(`InteractionTokenRefresh: Interaction time set: ${interactionTime}`, 'Component: Auth');

        authenticationWatcher.setLastInteractionTime(new Date().getTime());
    }

    function setLogoutWarningVisibility(show: boolean): void {
        log.info(`InteractionTokenRefresh#setLogoutWarningVisibility: ${show}`, 'Component: Auth');

        setIsLogoutWarningVisible(show);
    }

    const handleLogout: React.MouseEventHandler<HTMLDivElement> = () => {
        log.info(
            'InteractionTokenRefresh#handleLogout: User selected Logout on logout warning modal.',
            'Component: Auth',
        );

        applicationLogout();
    };

    const handleStayConnected: React.MouseEventHandler<HTMLDivElement> = () => {
        log.info(
            'InteractionTokenRefresh#handleStayConnected: User selected Stay Connected on logout warning modal.',
            'Component: Auth',
        );

        sessionKeepAlive();

        markInteracted();

        authenticationWatcher.setShowLogoutWarning(false);
    };

    log.info('InteractionTokenRefresh:', 'Component: Auth', 'Component re-created');

    // This runs only on page load
    useEffect(() => {
        log.debug('InteractionTokenRefresh#pageLoad: init state management', 'Component: Auth');

        const init = async (): Promise<void> => {
            authenticationWatcher.init(config);

            await sessionKeepAlive();

            await markInteracted();
        };

        init();

        authenticationWatcher.onSessionKeepAlive(sessionKeepAlive);
        authenticationWatcher.onRenewTokens(renewTokens);
        authenticationWatcher.onRemoveTokens(removeTokens);
        authenticationWatcher.onLogoutWarning(setLogoutWarningVisibility);

        const authState = oktaAuth.authStateManager.getAuthState();
        const accessToken = authState?.accessToken;
        authenticationWatcher.setToken(accessToken);

        const pageHideHandler = (): void => {
            authenticationWatcher.offLogoutWarning();
            authenticationWatcher.offRemoveTokens();
            authenticationWatcher.offRenewTokens();
            authenticationWatcher.offSessionKeepAlive();
        };

        window.addEventListener('pagehide', pageHideHandler);

        return () => {
            log.debug('InteractionTokenRefresh#pageLoad: removing state management', 'Component: Auth');

            window.removeEventListener('pagehide', pageHideHandler);

            authenticationWatcher.dispose();
        };
    }, []);

    useEffect(() => {
        const debounceHandler = debounce(markInteracted, 1000);

        if (isLogoutWarningVisible) {
            document.removeEventListener('keydown', debounceHandler);
            document.removeEventListener('mousedown', debounceHandler);
        } else {
            document.addEventListener('keydown', debounceHandler);
            document.addEventListener('mousedown', debounceHandler);
        }

        return () => {
            document.removeEventListener('keydown', debounceHandler);
            document.removeEventListener('mousedown', debounceHandler);
        };
    }, [isLogoutWarningVisible]);

    return (
        <>
            {children}
            {config.showLogoutWarning ? (
                <LogoutWarningModal
                    description="Your session is about to expire. Please save any work in progress."
                    isVisible={isLogoutWarningVisible}
                    handleLogout={handleLogout}
                    handleStayConnected={handleStayConnected}
                    showActions
                />
            ) : null}
        </>
    );
};

const InteractionTokenRefresh: React.FC<Partial<WithChildren>> = ({ children }) => {
    const config = useAuthenticationConfig();

    if (config.tokenRefreshMethod !== 'Interaction') return <>{children}</>;

    return <InnerInteractionTokenRefresh>{children}</InnerInteractionTokenRefresh>;
};

export default InteractionTokenRefresh;
