/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { Redirect, useHistory } from 'react-router-dom';
import { AuthStateEventHandler } from '@okta/okta-auth-js';
import PrettyCenteredBox from '../components/PrettyCenteredBox';
import { getLoginRedirectPath } from '../helpers/loginRedirectPathHelpers';
import { useAuthenticationConfig } from '../contexts';

const Login: React.FC = () => {
    const { oktaAuth, authState } = useOktaAuth();
    const history = useHistory();

    const { appName, loginRedirectPath, loginRedirectMethod, loginRedirectStrategy } = useAuthenticationConfig();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const log = (window as any).$log || console; // use centricity client logging if available.
    log.info(`loading login page`, 'Component: Auth', authState);

    const login = async (): Promise<void> => {
        if (!(await oktaAuth.isAuthenticated())) {
            log.debug('redirection to okta login', 'Component: Auth');

            // Start the browser based oidc flow, then parse tokens from the redirect callback url
            await oktaAuth.signInWithRedirect();
        }
    };

    // if a login is necessary, trigger it
    React.useEffect(() => {
        const goHome = (): void => {
            const href = getLoginRedirectPath(loginRedirectPath, {
                loginRedirectStrategy,
            });

            switch (loginRedirectMethod) {
                case 'RouterRedirect':
                    history.push(href);
                    break;
                case 'HardRedirect':
                default:
                    window.location.href = href;
            }
        };

        const timer = setTimeout(login, 3000);
        const stateHandler: AuthStateEventHandler = ({ isAuthenticated }) => {
            if (isAuthenticated) goHome();
        };

        oktaAuth.isAuthenticated().then((isCurrentlyAuthenticated) => {
            if (isCurrentlyAuthenticated) {
                clearTimeout(timer);
                goHome();
            } else {
                oktaAuth.authStateManager.subscribe(stateHandler);
            }
        });

        return () => {
            oktaAuth.authStateManager.unsubscribe(stateHandler);
            clearTimeout(timer);
        };
    }, []);

    return (
        <PrettyCenteredBox>
            <p className="mt-xl font-bold text-2">To log in to {appName}, you will be redirected momentarily.</p>
            <p className="text-2">
                If you are not automatically redirected in 10 seconds, please{' '}
                <a href="#" className="text-blue-5 underline" onClick={login}>
                    click here to log in
                </a>
                .
            </p>
        </PrettyCenteredBox>
    );
};

export default Login;
