import React from 'react';
import { SecureRoute } from '@okta/okta-react';
import { BrowserRouter as Router, Route, Switch, useHistory } from 'react-router-dom';
import { OktaAuth } from '@okta/okta-auth-js';
import { MessageBus } from '@samc/message-bus';
import { AuthenticationConfigProvider } from './contexts/AuthenticationConfigContext';
import { MessageBusProvider } from './contexts/MessageBusContext';
import toOktaAuth from './helpers/oktaAuthMapper';
import { AuthenticationConfig } from './models';
import Login from './views/Login';
import Logout from './views/Logout';
import LoggedOut from './views/LoggedOut';
import './Authentication.css';
import MouseMovementTokenRefresh from './MouseMovementTokenRefresh';
import InteractionTokenRefresh from './InteractionTokenRefresh';
import { WithChildren } from './types';
import { OktaManager } from './OktaManager';

type NavigationProps = Partial<WithChildren> & {
    oktaAuth: OktaAuth;
};

const Navigation: React.FC<NavigationProps> = ({ oktaAuth, children }) => {
    const history = useHistory();

    return (
        <OktaManager
            oktaAuth={oktaAuth}
            navigate={(href, strategy, replace) => {
                switch (strategy) {
                    case 'RouterRedirect':
                        if (replace) history.replace(href);
                        else history.push(href);
                        break;
                    case 'HardRedirect':
                    default:
                        if (replace) window.location.replace(href);
                        else window.location.href = href;
                }
            }}
        >
            <Switch>
                <Route path="/login" component={Login} />
                <Route path="/logout" component={Logout} />
                <Route path="/loggedOut" component={LoggedOut} />
                <SecureRoute path="/*">
                    <MouseMovementTokenRefresh>
                        <InteractionTokenRefresh>{children}</InteractionTokenRefresh>
                    </MouseMovementTokenRefresh>
                </SecureRoute>
            </Switch>
        </OktaManager>
    );
};

type AuthenticationProps = Partial<WithChildren> & {
    config: AuthenticationConfig;
    /**
     * @deprecated
     */
    hardRedirect?: boolean;
};

const Authentication: React.FC<AuthenticationProps> = ({ config, children }) => {
    const oktaAuth = React.useMemo(() => toOktaAuth(config), [config]);
    const [jwt, setJwt] = React.useState(oktaAuth.getAccessToken());

    React.useEffect(() => {
        const handler = (): void => setJwt(oktaAuth.getAccessToken());

        oktaAuth.authStateManager.subscribe(handler);
        return () => oktaAuth.authStateManager.unsubscribe(handler);
    }, []);

    const forwardedConfig = React.useMemo<AuthenticationConfig>(
        () => ({ ...config, client: { ...config.client, jwt: jwt ?? null } }),
        [config, jwt],
    );

    return (
        <div id="auth">
            <MessageBusProvider bus={new MessageBus()}>
                <AuthenticationConfigProvider config={forwardedConfig}>
                    <Router>
                        <Navigation oktaAuth={oktaAuth}>{children}</Navigation>
                    </Router>
                    <link
                        href="https://fonts.googleapis.com/css2?family=Noto+Sans&amp;family=Roboto&amp;display=swap"
                        rel="stylesheet"
                    />
                </AuthenticationConfigProvider>
            </MessageBusProvider>
        </div>
    );
};

export default Authentication;
