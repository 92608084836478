import React, { useState, useEffect } from 'react';
import { useClient } from '../contexts/ClientContext';
import Patience from '../components/Patience';
import Entitlement from '../models/Entitlement';
import Grid from '../components/grid/Grid';
import { columnDef } from '../helpers/columnDef';

const columnDefs = [
    columnDef('Name', 'displayName'),
    columnDef('Group', 'group'),
    columnDef('Description', 'description'),
];

const Entitlements = (): React.ReactElement => {
    const client = useClient();
    const [isLoaded, setIsLoaded] = useState(false);
    const [entitlements, setEntitlements] = useState(new Array<Entitlement>());

    useEffect(() => {
        if (client.isReady) {
            setIsLoaded(false);
            client.entitlements.listEntitlements().then((e) => {
                setEntitlements(e);
                setIsLoaded(true);
            });
        }
    }, [client]);
    return (
        <Patience showPatience={!isLoaded}>
            <Grid columnDefs={columnDefs} rowData={entitlements} paging />
        </Patience>
    );
};

export default Entitlements;
