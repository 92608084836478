import React, { useEffect, useContext, useState } from 'react';
import { useClient } from './ClientContext';
import { ServiceConfig } from '../models/ServiceConfig';
import { WithChildren } from '../types';

const ServiceConfigContext = React.createContext<ServiceConfig | null | undefined>(undefined);

const ServiceConfigProvider: React.FC<Partial<WithChildren>> = ({ children }) => {
    const client = useClient();
    const [config, SetConfig] = useState<ServiceConfig | null>(null);

    useEffect(() => {
        const isCanceled = false;
        if (client.isReady) {
            client.config.getConfig().then((_config) => {
                if (!isCanceled) {
                    SetConfig(_config);
                }
            });
        }
    }, [client]);

    return <ServiceConfigContext.Provider value={config}>{children}</ServiceConfigContext.Provider>;
};

function useServiceConfig(): ServiceConfig | null {
    const context = useContext(ServiceConfigContext);
    if (context === undefined) {
        throw new Error('useServiceConfig must be used within a CurrentUserProvider!');
    }
    return context;
}

export { ServiceConfigProvider, useServiceConfig };
