import React, { useState, useEffect, CSSProperties } from 'react';
import { ColDef, ColGroupDef, FirstDataRenderedEvent, MenuItemDef } from 'ag-grid-community';
import { DefaultPublisher } from '@samc/common';
import { useClient } from '../contexts/ClientContext';
import Patience from '../components/Patience';
import Role from '../models/Role';
import Grid from '../components/grid/Grid';
import { dateFilterParams } from '../helpers/columnDef';
import { Renderers } from '../components/grid/GridRenderers';
import { RoleSavedEvent, EditRoleEvent } from '../events';
import { AdditionalUserSettings } from '../models';

const minWidth = 177;
const columnDefs: (ColDef | ColGroupDef)[] = [
    {
        headerName: 'Name',
        field: 'displayName',
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        cellRenderer: Renderers.EditorActionRenderer,
    },
    { headerName: 'Description', field: 'description', floatingFilter: true, filter: 'agTextColumnFilter' },
    {
        headerName: 'Updated',
        minWidth,
        field: 'updated',
        cellRenderer: Renderers.DateRenderer,
        filter: 'agDateColumnFilter',
        floatingFilter: true,
        type: 'leftAligned',
        filterParams: dateFilterParams,
        floatingFilterComponentParams: { suppressFilterButton: false },
    },
    {
        headerName: 'Updated By',
        field: 'updatedByUser',
        cellRenderer: Renderers.UserRenderer,
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        floatingFilterComponentParams: { suppressFilterButton: false },
    },
];

type RoleProps = {
    gridContainerStyling?: CSSProperties;
    userDataFieldSettings?: AdditionalUserSettings;
};

const Roles: React.FC<RoleProps> = ({
    gridContainerStyling,
    userDataFieldSettings: _userDataFieldSettings = new AdditionalUserSettings(),
}) => {
    const client = useClient();
    const [isLoaded, setIsLoaded] = useState(false);
    const [roles, setRoles] = useState(new Array<Role>());

    const userDataFieldSettings = React.useMemo(
        () => (_userDataFieldSettings === undefined ? new AdditionalUserSettings() : _userDataFieldSettings),
        [_userDataFieldSettings],
    );

    const _columnDefs = React.useMemo(
        () =>
            columnDefs.map((x: ColDef) => {
                const _output = { ...x };

                if (_output.field === 'updatedBy' || _output.field === 'updatedByUser') {
                    _output.cellRendererParams = { userDataFieldSettings };
                }

                return _output;
            }),
        [],
    );

    const reload = () => {
        setIsLoaded(false);
        let cancelled = false;
        client.roles.listRoles().then((r) => {
            if (!cancelled) {
                setRoles(r);
                setIsLoaded(true);
            }
        });
        return () => {
            cancelled = true;
        };
    };
    useEffect(() => {
        if (client.isReady) {
            const cancellation = reload();
            return cancellation;
        }
        return () => {};
    }, [client]);

    useEffect(() => {
        const subscriptionId = DefaultPublisher.subscribe<RoleSavedEvent>(RoleSavedEvent.eventName, reload);
        return () => DefaultPublisher.unsubscribe(RoleSavedEvent.eventName, subscriptionId);
    }, [client]);

    const onFirstDataRendered = (event: FirstDataRenderedEvent): void => {
        event.api.autoSizeAllColumns();
    };

    const createRole = (): void => {
        DefaultPublisher.publish(new EditRoleEvent(new Role()));
    };
    const buttons = [];
    buttons.push({
        key: 'createRole',
        children: (
            <span className="font-proxima text-1">
                <i className="mdi mdi-plus-circle-outline text-4" /> ROLE
            </span>
        ),
        action: createRole,
        entitlement: 'Create Role',
    });

    const contextMenu = <TData,>(data?: TData): Array<MenuItemDef> => {
        const result = new Array<MenuItemDef>();
        if (data instanceof Role) {
            result.push({
                name: 'Clone as a new role',
                action: () => {
                    DefaultPublisher.publish(new EditRoleEvent(data, true));
                },
            });
        }
        return result;
    };

    return (
        <Patience showPatience={!isLoaded}>
            <Grid
                columnDefs={_columnDefs}
                rowData={roles}
                buttonProps={buttons}
                paging
                canUseFullScreen
                excelExportAction={() => client.roles.downloadRolesExport()}
                containerStyling={gridContainerStyling}
                onFirstDataRendered={onFirstDataRendered}
                getContextMenuItems={contextMenu}
            />
        </Patience>
    );
};

export default Roles;
