import React from 'react';
import ReactDOM from 'react-dom';
// eslint-disable-next-line import/no-extraneous-dependencies
import singleSpaReact from 'single-spa-react';
import { Root } from './root.component';

const spaElementId = '@samc/single-spa-authentication';

const domElementGetter = (): HTMLElement => {
    let el = document.getElementById(spaElementId);
    if (!el) {
        el = document.createElement('div');
        el.id = spaElementId;
        document.body.appendChild(el);
    }

    return el;
};

const lifecycles = singleSpaReact({
    React,
    ReactDOM,
    rootComponent: Root,
    errorBoundary(_err, _info, _props) {
        // Customize the root error boundary for your microfrontend here.
        // eslint-disable-next-line react/jsx-no-useless-fragment
        return <></>;
    },
    domElementGetter,
});

export const { bootstrap, mount, unmount } = lifecycles;

// List any exports to expose to other microfrontends here.
export { useToken } from './hooks/useToken';
export { useAuthenticationConfig } from './hooks/useAuthenticationConfig';
export { useUserAuthenticationState } from './hooks/useUserAuthState';
export { useUser } from './hooks/useUser';
export { userConfig$, userToken$, userAuthState$, user$ } from './utilities/userConfigData';
export { AuthorizationWrapper } from './components/AuthorizationWrapper';
export { Entitlements } from './Entitlements';

// Passthrough imports from other packages
export { useCurrentUser } from '@samc/vmsnext-authorization';
