import { useState, useEffect, useCallback } from 'react';
import PubSub from 'pubsub-js';

type StorageHandlerAsObject = {
    value: unknown;
    set: (newValue: unknown) => void;
    remove: () => void;
};

type StorageHandlerAsArray = [unknown, (newValue: unknown) => void, () => void];

type StorageHandler = StorageHandlerAsArray & StorageHandlerAsObject;

const getValueFromLocalStorage = (key: string): unknown => {
    if (typeof localStorage === 'undefined') {
        return null;
    }
    const storedValue = localStorage.getItem(key) || 'null';
    try {
        return JSON.parse(storedValue);
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
    }

    return storedValue;
};

const saveValueToLocalStorage = (key: string, valueToSet: unknown): void => {
    if (typeof localStorage === 'undefined') {
        return;
    }

    localStorage.setItem(key, JSON.stringify(valueToSet));
    PubSub.publish(key, JSON.stringify(valueToSet));
};

/**
 * useLocalstorage hook
 * Tracks a value within localStorage and updates it
 *
 * @param {string} key - Key of the localStorage object
 * @param {any} defaultValue - Default initial value
 */
function useLocalstorage(key: string, defaultValue: unknown = null): StorageHandler {
    const [value, setValue] = useState(getValueFromLocalStorage(key));

    const set = useCallback(
        (newValue: unknown): void => {
            setValue(newValue);
            saveValueToLocalStorage(key, newValue);
        },
        [key],
    );

    const init = (): void => {
        const valueLoadedFromLocalStorage = getValueFromLocalStorage(key);
        if (valueLoadedFromLocalStorage === null || valueLoadedFromLocalStorage === 'null') {
            set(defaultValue);
        }
    };

    const subscribe = useCallback(
        (storageKey: string, newValue: unknown) => {
            if (storageKey === key) {
                setValue(newValue);
            }
        },
        [key],
    );

    const listenToLocalStorage = useCallback(
        (event: StorageEvent) => {
            if (event.storageArea === localStorage && event.key === key) {
                setValue(event.newValue);
            }
        },
        [key],
    );

    const remove = useCallback((): boolean => {
        set(null);
        if (typeof localStorage === 'undefined') {
            return false;
        }
        localStorage.removeItem(key);
        return true;
    }, [key, set]);

    // initialize
    useEffect(() => {
        init();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // check for changes across windows
    useEffect(() => {
        window.addEventListener('storage', listenToLocalStorage);

        return (): void => {
            window.removeEventListener('storage', listenToLocalStorage);
        };
    }, [listenToLocalStorage]);
    // check for changes in current window
    useEffect(() => {
        PubSub.subscribe(key, subscribe);

        return (): void => {
            PubSub.unsubscribe(subscribe);
        };
    }, [key, subscribe]);

    const handler = Object.assign([value, set, remove], {
        value,
        remove,
        set,
    });

    return handler as unknown as StorageHandler;
}

export default useLocalstorage;
