import { isValid } from 'date-fns';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseDate(dateValue: any, currentDate: Date): Date {
    if (dateValue) {
        if (typeof dateValue === 'string') {
            const date = new Date(dateValue);
            if (isValid(date)) {
                return date;
            }
        } else if (typeof dateValue.getMonth === 'function') {
            return dateValue as Date;
        }
    }
    // dateValue is neither a string nor a date, so return the existing value
    return currentDate;
}
