/* eslint-disable @typescript-eslint/no-shadow */
import EditorActionRenderer from './EditorActionRenderer';
import DateRenderer from './DateRenderer';
import UserRenderer from './UserRenderer';
import CheckboxRenderer from './CheckboxRenderer';
import CheckRenderer from './CheckRenderer';
import UserStatusRenderer from './UserStatusRenderer';
import ModelNameRenderer from './ModelNameRenderer';

export enum Renderers {
    EditorActionRenderer = 'editorActionRenderer',
    DateRenderer = 'dateRenderer',
    UserRenderer = 'userRenderer',
    CheckRenderer = 'checkRenderer',
    CheckboxRenderer = 'checkboxRenderer',
    UserStatusRenderer = 'userStatusRenderer',
    ModelNameRenderer = 'modelNameRenderer',
    Default = '',
}

export { EditorActionRenderer, DateRenderer, UserRenderer, CheckRenderer, CheckboxRenderer };

// eslint-disable-next-line @typescript-eslint/ban-types
export function addRenderers<T extends {}>(frameworkComponents: T): T {
    return Object.assign(frameworkComponents, {
        [Renderers.EditorActionRenderer.toString()]: EditorActionRenderer,
        [Renderers.DateRenderer.toString()]: DateRenderer,
        [Renderers.UserRenderer.toString()]: UserRenderer,
        [Renderers.CheckRenderer.toString()]: CheckRenderer,
        [Renderers.CheckboxRenderer.toString()]: CheckboxRenderer,
        [Renderers.UserStatusRenderer.toString()]: UserStatusRenderer,
        [Renderers.ModelNameRenderer.toString()]: ModelNameRenderer,
    });
}
