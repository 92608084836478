/* *
 * This file was created by the need of other single-spa's to get the session data and any forward
 * changes such as the user configuration and token, among others. The observers are updated within
 * the AuthenticationManager.tsx file whenever any configuration is updated through the
 * '@samc/vmsnext-authentication-okta' package.
 * The observables are exported with the observables convention (obs$) and must subscribe to it
 * within any other single-spa depending on data to be required in such spa.
 * Example:
 *      userToken$.subscribe(token => console.log)
 *      // This logs the data received over time whenever the token gets an update.
 * */

import { AuthState, UserClaims } from '@okta/okta-auth-js';
import { ClientConfiguration } from '@samc/single-spa-client-configuration';
import { BehaviorSubject } from 'rxjs';

// Creating subjects to mutate data over time
const configSubject = new BehaviorSubject<ClientConfiguration | null>(null);
const tokenSubject = new BehaviorSubject<string | null>(null);
const authStateSubject = new BehaviorSubject<AuthState | null>(null);
const userSubject = new BehaviorSubject<UserClaims | null>(null);

// Defining observables from subjects to being able to subscribe to change updates.
const userConfig$ = configSubject.asObservable();
const userToken$ = tokenSubject.asObservable();
const userAuthState$ = authStateSubject.asObservable();
const user$ = userSubject.asObservable();

/**
 *
 * Updates the user config data subject by triggering the observer next() method
 * Must have been subscribed to the userConfig$ observable to listen to changes.
 * @param config - receives an object or a null assignment
 */
const emitUserConfig = (config: ClientConfiguration | null): void => configSubject.next(config);

/**
 *
 * Updates the user token data subject by triggering the observer next() method.
 * Must have been subscribed to the userToken$ observable to listen to changes.
 * @param token - receives a string or a null assignment
 */
const emitUserToken = (token: string | null): void => tokenSubject.next(token);

/**
 *
 * Updates the user data subject by triggering the observer next() method.
 * Must have been subscribed to the user$ observable to listen to changes.
 * @param user - receives a object or a null assignment
 */
const emitUser = (user: UserClaims | null): void => userSubject.next(user);

/**
 *
 * Indicates if the user has been authenticated by triggering the observer next() method.
 * Must have been subscribed to the userAuthState$ observable to listen to changes.
 * @param state - receives an object or null assignment
 */
const emitUserAuthState = (state: AuthState | null): void => authStateSubject.next(state);

export { userConfig$, userToken$, userAuthState$, user$, emitUserConfig, emitUserToken, emitUserAuthState, emitUser };
