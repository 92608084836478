import React from 'react';
import { AccessToken, AuthState } from '@okta/okta-auth-js';
import { isNullOrUndefined } from '@samc/common';
import { userAuthState$ } from '../utilities/userConfigData';

/**
 * Adds value back to AccessToken for backwards compatibility
 */
interface SitusAuthState extends AuthState {
    accessToken?: AccessToken & { value: string };
}

/**
 * Type-guarded inversion of isNullOrUndefined
 */
const isDefined = (thing: AuthState | null): thing is AuthState => !isNullOrUndefined(thing);

export const useUserAuthenticationState = (): SitusAuthState | null => {
    const [authState, setUserAuthState] = React.useState<SitusAuthState | null>(null);

    React.useEffect(() => {
        const subscriptor = userAuthState$.subscribe((state) => {
            // May not be defined at page load, in which case do nothing
            if (!isDefined(state)) return;

            const { accessToken } = state;

            setUserAuthState({
                ...state,
                accessToken: accessToken
                    ? {
                          ...accessToken,
                          value: accessToken.accessToken,
                      }
                    : undefined,
            });
        });

        return (): void => subscriptor.unsubscribe();
    }, []);

    return authState;
};

export default useUserAuthenticationState;
