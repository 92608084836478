import * as Comlink from 'comlink';
import { AccessToken } from '@okta/okta-auth-js';
import { v4 as uuidv4 } from 'uuid';
import { AuthenticationConfig } from './models';

export class AuthenticationWatcher {
    /**
     * The shared worker that allows use to communicate between windows without an issue
     */
    private readonly _worker: SharedWorker;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private _authenticationContext: any;

    private _uuid: string;

    private _logoutWarningCallback: ((visibility: boolean) => void) & Comlink.ProxyMarked & Comlink.ProxyMethods;

    private _renewTokensCallback: (() => void) & Comlink.ProxyMarked & Comlink.ProxyMethods;

    private _removeTokensCallback: (() => void) & Comlink.ProxyMarked & Comlink.ProxyMethods;

    private _sessionKeepAliveCallback: (() => void) & Comlink.ProxyMarked & Comlink.ProxyMethods;

    public constructor() {
        this._worker = new SharedWorker(new URL('authentication_worker.js', import.meta.url), {
            name: 'vmsnext-authentication-okta',
            type: 'module',
        });
        this._authenticationContext = Comlink.wrap(this._worker.port);
        this._uuid = uuidv4();
    }

    public init(config: AuthenticationConfig): void {
        this._authenticationContext.init(config);
    }

    public setShowLogoutWarning(show: boolean): void {
        this._authenticationContext.setShowLogoutWarning(show);
    }

    public setToken(accessToken: AccessToken | undefined | null): void {
        this._authenticationContext.setToken(accessToken);
    }

    public setLastInteractionTime(lastInteractionTime: number): void {
        this._authenticationContext.setLastInteractionTime(lastInteractionTime);
    }

    public onLogoutWarning(setLogoutWarningVisibility: (visibility: boolean) => void): void {
        this._logoutWarningCallback = Comlink.proxy(setLogoutWarningVisibility) as ((visibility: boolean) => void) &
            Comlink.ProxyMarked &
            Comlink.ProxyMethods;
        this._authenticationContext.addLogoutWarningListener(this._uuid, this._logoutWarningCallback);
    }

    public async offLogoutWarning(): Promise<void> {
        if (!this._logoutWarningCallback) return;

        await this._authenticationContext.removeLogoutWarningListener(this._uuid);
        await this._logoutWarningCallback[Comlink.releaseProxy]();
    }

    public onRenewTokens(renewTokens: () => void): void {
        this._renewTokensCallback = Comlink.proxy(renewTokens) as (() => void) &
            Comlink.ProxyMarked &
            Comlink.ProxyMethods;
        this._authenticationContext.addRenewTokensListener(this._uuid, this._renewTokensCallback);
    }

    public async offRenewTokens(): Promise<void> {
        if (!this._renewTokensCallback) return;

        await this._authenticationContext.removeRenewTokensListener(this._uuid);
        await this._renewTokensCallback[Comlink.releaseProxy]();
    }

    public onRemoveTokens(removeTokens: () => void): void {
        this._removeTokensCallback = Comlink.proxy(removeTokens) as (() => void) &
            Comlink.ProxyMarked &
            Comlink.ProxyMethods;
        this._authenticationContext.addRemoveTokensListener(this._uuid, this._removeTokensCallback);
    }

    public async offRemoveTokens(): Promise<void> {
        if (!this._removeTokensCallback) return;

        await this._authenticationContext.removeRemoveTokensListener(this._uuid);
        await this._removeTokensCallback[Comlink.releaseProxy]();
    }

    public onSessionKeepAlive(sessionKeepAlive: () => void): void {
        this._sessionKeepAliveCallback = Comlink.proxy(sessionKeepAlive) as (() => void) &
            Comlink.ProxyMarked &
            Comlink.ProxyMethods;
        this._authenticationContext.addSessionKeepAliveListener(this._uuid, this._sessionKeepAliveCallback);
    }

    public async offSessionKeepAlive(): Promise<void> {
        if (!this._sessionKeepAliveCallback) return;

        await this._authenticationContext.removeSessionKeepAliveListener(this._uuid);
        await this._sessionKeepAliveCallback[Comlink.releaseProxy]();
    }

    public async dispose(): Promise<void> {
        if (!this._authenticationContext) return;

        await this._authenticationContext[Comlink.releaseProxy]();
    }
}
